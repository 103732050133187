var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Page to manage PoCR improvement proposals.")])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.isConnected)?_c('v-card',{staticClass:"pa-2"},[_c('v-card-subtitle',[_vm._v("Create a new proposal.")]),_c('v-card-text',[_vm._v(" this action will be done with your connected wallet and will reserve the ID of the Climate awaReness Improvement Proposal that you must then create as a document in "),_c('a',{attrs:{"href":_vm.CRIPsUrl}},[_vm._v(_vm._s(_vm.CRIPsUrl))])]),_c('v-card-actions',[(!_vm.lastCRIPCreated)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.reserveCRIP}},[_vm._v(" Reserve the next CRIP id ")]):_c('v-card-text',[_vm._v(" You have reserved the CRIP id "),_c('code',[_vm._v(_vm._s(_vm.lastCRIPCreated))])])],1)],1):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_vm._v("List of the improvement proposals")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.CRIPS,"headers":_vm.cripsTableHeaders,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdBlock",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.createdBlock)+" "),_c('explorer',{attrs:{"type":"block","id":item.createdBlock}})],1)]}},{key:"item.documentation",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url}},[_vm._v("crip-"+_vm._s(item.index)+".md")])]}},{key:"item.voteFromBlock",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.voteFromContent(item.index)))]),_c('div',[_vm._v("at "+_vm._s(item.voteFromBlock))])]}},{key:"item.voteUntilBlock",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.voteUntilContent(item.index)))]),_c('div',[_vm._v("at "+_vm._s(item.voteUntilBlock))])]}},{key:"item.auditors",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("Pro: "+_vm._s(item.auditorsFor))]),_c('div',[_vm._v("Cons: "+_vm._s(item.auditorsAgainst))])]}},{key:"item.nodes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("Pro: "+_vm._s(item.nodesFor))]),_c('div',[_vm._v("Cons: "+_vm._s(item.nodesAgainst))])]}},{key:"item.useraction",fn:function(ref){
var item = ref.item;
return [(_vm.canVote(item.index))?_c('v-radio-group',{on:{"change":function($event){return _vm.userVote(item)}},model:{value:(item.vote),callback:function ($$v) {_vm.$set(item, "vote", $$v)},expression:"item.vote"}},[_c('v-radio',{attrs:{"label":"agree","value":true}}),_c('v-radio',{attrs:{"label":"reject","value":false}})],1):_vm._e()]}}])})],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_vm._v("Your votes")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.userVotes,"headers":_vm.votesTableHeaders,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.block)+" "),_c('explorer',{attrs:{"type":"tx","id":item.txHash}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }