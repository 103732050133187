<template>
  <div>
    <div>Anonymous access:</div>
    <v-row>
      <v-col v-for="network of chainsNetworks" :key="network.chainId">
        <v-btn 
          color="teal" class=" mr-3" dark
          @click="openWeb3DirectAnonymousConnection(network)"
        >{{ network.name }}</v-btn>
        <div class="text-caption">{{ network.title }}</div>
      </v-col>
    </v-row>
    <br><br>
    <div v-if="hasProviderMetamask">
      <div>Authenticated access via Metamask:</div>
      <v-row>
        <v-col>
          <v-btn color="teal" class=" my-3" dark @click="prepareOpenMetaMaskConnectionDialog"
            >Connect Metamask Wallet</v-btn>
        </v-col>
        <v-col>
          <v-btn class="mx-3 my-3" color="teal" dark @click="goToInstallMetamask"
            >Switch / Install network</v-btn>
        </v-col>
      </v-row>

    </div>
    <div v-else>
      <div>Install Metamask to authenticate and then reload this application.</div>
      <div>Installation instructions available at <a href="https://metamask.io/download/" target="_blank">https://metamask.io/download/</a></div>
    </div>
    <br><br>
    <div v-if="hasProviderDirect">
      <div>Authenticated access via wallet custody:</div>
      <v-btn color="teal" dark @click="prepareOpenWeb3DirectConnectionDialog"
        >Connect with below configuration</v-btn
      > <br><br>
      <v-select
          v-model="selectedNetwork"
          :hint="`node: ${selectedNetwork.nodeUrl}; custody: ${selectedNetwork.walletCustodyAPIBaseUrl}`"
          :items="networks"
          item-text="name"
          item-value="nodeUrl"
          persistent-hint
          return-object
          single-line
        ></v-select>
      <div v-if="hasCustodyApi">
        <br>
        <v-combobox
              label="Enter the wallet address"
              placeholder="capture your wallet address"
              outlined counter clearable
              :search-input="wallet"
              @update:search-input="updateWallet"
              :items="wallets"
            ></v-combobox>
        <v-text-field
              label="Enter the wallet password"
              placeholder="capture your wallet password"
              outlined 
              :type="show?'text' : 'password'"
              @click:append="show = !show"
              v-model="password"
            ></v-text-field>
      </div>

    </div>
  </div>
</template>

<script>
import { call, get } from "vuex-pathify";
import { getCustodyLastWallets } from "../lib/api";
import { getDefaultNetwork, getNetworkList } from "../lib/config-file";

export default {
  data() {
    return {
      wallet: undefined,
      wallets: [],
      networks: [],
      selectedNetwork: {name: "undefined"},
      password: undefined,
      show: false,
    }
  },
  async mounted() {

    const wallets = await getCustodyLastWallets();
    if (wallets.length>0) this.wallet = wallets[0];
    this.wallets = wallets;

    this.disconnect();
    const config = this.$store.get("config");
    this.networks = getNetworkList(config);
    this.selectedNetwork = getDefaultNetwork(config);
  },
  computed: {
    ...get("auth",["chainsNetworks"]),
    hasProviderMetamask() {
      if (this.$store.state.auth.providerMetamask) return true;
      else return false;
    },
    hasProviderDirect() {
      if (this.$store.state.auth.providerDirect) return true;
      else return false;
    },
    hasCustodyApi() {
      if (this.$store.state.auth.providerDirect && this.$store.state.auth.providerDirect.custodyModel == "api" ) return true;
      else return false;
    },
  },
  methods: {
    ...call(["errorFlash"]),
    ...call("auth", ["openMetaMaskConnectionDialog", "openWeb3DirectConnectionDialog", "openWeb3DirectAnonymousConnection", "setConnection", "checkNetworkProofOfCarbonReduction", "disconnect"]),
    updateWallet(v) {
      this.wallet = v;
    },
    async prepareOpenMetaMaskConnectionDialog() {
      const metamask = this.$store.state.auth.providerMetamask;
      if (!metamask) return;

      await this.setConnection(metamask);

      if (!await this.checkNetworkProofOfCarbonReduction()) {
        try {
          await this.openMetaMaskConnectionDialog()
        } catch (error) {
          this.errorFlash("Could not connect an account from the wallet: " + error.message);
          return;
        }
        this.goToDashboard()
      } else {
        this.goToInstallMetamask()
      }

    },
    async prepareOpenWeb3DirectConnectionDialog() {
      await this.openWeb3DirectConnectionDialog({wallet: this.wallet, password: this.password, nodeUrl: this.selectedNetwork.nodeUrl});
      this.wallets = await getCustodyLastWallets();
    },

    goToInstallMetamask() {
      this.$router.push({ name: 'installMetaMask' })
    },

    goToDashboard() {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
